<script setup lang="ts">
import { observeSizeChange } from '@@/bits/resize_observer'
import { uniqueId } from 'lodash-es'
import type { Data } from 'vue'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, useAttrs, watch } from 'vue'
/**
 * *Note: Do NOT put CSS transition for height on this element, if you do the autosizing will NOT work.
 * Any style/class prop passsed to this component will be applied to the parent div container,
 * and fallthrough attributes such aria attributes will be passed onto the input element.

 * Compared to the old multiline_input, the '@input' and '@enter' events emit the event object, not the string value.
 *
 * Recommended usage:
 * <OzMultilineInput :value="value" @input="onInput" @enter="onEnter" />
 */

export type OzMultilineInputBorder = 'solid_full' | 'full' | 'bottom' | 'none'
export type OzMultilineInputFontSize = 14 | 17 | 20

const props = withDefaults(
  defineProps<{
    value?: string
    disabled?: boolean
    required?: boolean
    darkMode?: boolean | 'auto'
    border?: OzMultilineInputBorder
    fontSize?: OzMultilineInputFontSize
    placeholder?: string
    readonly?: boolean
    dir?: 'ltr' | 'rtl' | 'auto'
    allowNewlines?: boolean
    isValid?: boolean
    validationMessage?: string
    testId?: string
    minHeight?: number
    maxLength?: number
    paddingClass?: string
    isScrollable?: boolean
    inputClasses?: string
    ariaDescribedby?: string
    cols?: number // TODO (PRO-15397): Remove this prop after the otherAttrs non-reactivity issue is fixed
  }>(),
  {
    value: '',
    disabled: false,
    required: false,
    darkMode: 'auto',
    border: 'full',
    fontSize: 17,
    placeholder: '',
    maxLength: undefined,
    readonly: false,
    dir: 'auto',
    allowNewlines: false,
    isValid: true,
    validationMessage: '',
    testId: 'multilineInput',
    minHeight: 0,
    paddingClass: undefined,
    isScrollable: false,
    inputClasses: undefined,
    ariaDescribedby: undefined,
    cols: undefined,
  },
)

const emit = defineEmits<{
  (e: 'input', event: InputEvent): void
  (e: 'enter', event: Event): void
  (e: 'blur', event: Event): void
  (e: 'click', event: Event): void
  (e: 'escape', event: Event): void
  (e: 'focus', event: FocusEvent): void
  (e: 'focusout', event: FocusEvent): void
  (e: 'keyup', event: Event): void
  (e: 'paste', event: ClipboardEvent): void
  (e: 'arrow-down-on-last-line'): void
  (e: 'arrow-up-on-first-line'): void
}>()

const {
  class: containerClass,
  style: containerStyle,
  ...otherAttrs
} = useAttrs() as { class?: string; style?: string; otherAttrs: Data }

const inputElement = ref<HTMLTextAreaElement>()
const height = ref<null | number>(null)
const sizeObserver = ref<ResizeObserver | null>(null)
const isInputFocused = ref(false)

defineExpose({
  focus: () => {
    nextTick(() => inputElement.value?.focus())
  },
  blur: () => {
    inputElement.value?.blur()
  },
  select: () => {
    nextTick(() => inputElement.value?.select())
  },
  height,
})

const style = computed(() => {
  if (!height.value) return {}
  if (props.isScrollable) return { height: `${height.value}px`, maxHeight: `${props.minHeight}px`, overflowY: 'auto' }
  return { height: `${height.value}px` }
})

const currentValue = ref(props.value)

const VALIDATION_MESSAGE_ID = uniqueId('validationMessageId')

const ariaDescribedbyValue = computed<string>(() => {
  if (props.ariaDescribedby) return `${VALIDATION_MESSAGE_ID} ${props.ariaDescribedby}`
  return VALIDATION_MESSAGE_ID
})

function onFocus(e: Event): void {
  isInputFocused.value = true
  emit('focus', e)
}

function onFocusOut(e: Event): void {
  isInputFocused.value = false
  emit('focusout', e)
}

function onInput(e: Event): void {
  removeNewlinesIfDisallowed()
  emit('input', e)
}

function onEnter(e: KeyboardEvent): void {
  // In languages like Chinese, Japanese, & Korean, users input characters using an IME.
  // If they are in composing mode (usually indicated by an underline under the word),
  // and they press Enter, we could receive 2 keydown events. Most sources say this is
  // a bug. We can ignore one of the 2 events by checking isComposing or keyCode === 229
  // for legacy browsers.
  // https://github.com/vuejs/vue/issues/10277#issuecomment-873337252
  if (e.isComposing || e.keyCode === 229) return
  if (props.allowNewlines) return
  emit('enter', e)
  e.preventDefault()
}

function onArrowDown(): void {
  // emit an event when arrow down key is pressed and the cursor is on the last line of the textarea
  // the default behaviour for arrow down on the last line is move to the end of the line
  // we only emit a special event to be handled if the cursor is already at the end of the last line => check selectionStart === value.length
  if (inputElement.value?.selectionStart === inputElement.value?.value.length) {
    emit('arrow-down-on-last-line')
  }
}

function onArrowUp(): void {
  // emit an event when arrow up key is pressed and the cursor is on the first line of the textarea
  // the default behaviour for arrow up on the first line is move to the start of the line
  // we only emit a special event to be handled if the cursor is already at the start of the first line => check selectionStart === 0
  if (inputElement.value?.selectionStart === 0) {
    emit('arrow-up-on-first-line')
  }
}

function removeNewlinesIfDisallowed(): void {
  if (props.allowNewlines) return
  const sanitizedValue = currentValue.value.replace(/\n|\r/g, '')
  if (currentValue.value !== sanitizedValue) {
    currentValue.value = sanitizedValue
  }
}

function autosize(): void {
  height.value = null // Otherwise scrollHeight will just be the previous height
  nextTick(() => {
    if (inputElement.value) {
      height.value = Math.max(inputElement.value.scrollHeight, props.minHeight)
      if (inputElement.value.scrollHeight === 0) {
        setTimeout(() => {
          if (inputElement.value) {
            height.value = Math.max(inputElement.value.scrollHeight, props.minHeight)
          }
        }, 0)
      }
    }
  })
}

watch(
  () => props.value,
  (newValue) => {
    currentValue.value = newValue || ''
    autosize()
    removeNewlinesIfDisallowed()
  },
  { immediate: true },
)

onMounted(() => {
  sizeObserver.value = observeSizeChange(inputElement.value as HTMLElement, () => autosize())
})

onBeforeUnmount(() => {
  sizeObserver.value?.disconnect()
  sizeObserver.value = null
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<template>
  <div
    :class="[
      'relative',
      border === 'solid_full' && [
        'rounded-2xl',
        'border-2 border-solid',
        isValid
          ? {
              ...(isInputFocused && {
                'border-grape-500 dark:border-canary-500': darkMode === 'auto',
                'border-grape-500': darkMode === false,
                'border-canary-500': darkMode === true,
              }),
              ...(!isInputFocused && {
                'border-dark-ui-100 dark:border-light-ui-100': darkMode === 'auto',
                'border-dark-ui-100': darkMode === false,
                'border-light-ui-100': darkMode === true,
              }),
            }
          : 'border-danger-100',
      ],
      border === 'full' && [
        'rounded-2xl',
        'border-2 border-solid',
        isValid
          ? {
              ...(isInputFocused && {
                'border-grape-500 dark:border-canary-500': darkMode === 'auto',
                'border-grape-500': darkMode === false,
                'border-canary-500': darkMode === true,
              }),
              ...(!isInputFocused && { 'border-transparent': true }),
            }
          : 'border-danger-100',
      ],
      border === 'bottom' && [
        'border-solid',
        isValid && {
          'border-b-0.5': true,
          'border-divider-gray dark:border-divider-gray-dark': darkMode === 'auto',
          'border-divider-gray': darkMode === false,
          'border-divider-gray-dark': darkMode === true,
        },
        !isValid && 'border-b-1 border-danger-100',
      ],
      border === 'none' && [!isValid && 'border-b-1 border-solid border-danger-100'],
      containerClass,
    ]"
    :style="containerStyle"
  >
    <!--
    Without row="1", scrollHeight will be the height of two lines,
    even if there's only one line of text.

    We also add a box-shadow none to remove the default focus style applied by kit.scss
  -->
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <textarea
      ref="inputElement"
      v-model="currentValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :rows="1"
      :cols="cols"
      :maxlength="maxLength"
      :data-testid="testId"
      :style="{ ...style, boxShadow: 'none' }"
      :class="[
        'resize-none box-border overflow-hidden w-full m-0 p-0',
        'outline-none border-none bg-transparent',
        {
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
        },
        {
          'placeholder:text-dark-text-300 dark:placeholder:text-light-text-300': darkMode === 'auto',
          'placeholder:text-dark-text-300': darkMode === false,
          'placeholder:text-light-text-300': darkMode === true,
        },
        'font-sans',
        {
          'text-body-small': fontSize === 14,
          'text-body-posts': fontSize === 17,
          'text-body-large': fontSize === 20,
        },
        (border === 'solid_full' || border === 'full') && [paddingClass || 'py-1.75 ps-4 pe-3'],
        (border === 'bottom' || border === 'none') && [paddingClass || 'py-3 pe-3'],
        inputClasses,
      ]"
      :dir="dir"
      :readonly="readonly"
      :aria-invalid="isValid ? 'false' : 'true'"
      :aria-errormessage="VALIDATION_MESSAGE_ID"
      :aria-describedby="ariaDescribedbyValue"
      :aria-required="required || undefined"
      v-bind="{ ...otherAttrs }"
      @click="emit('click', $event)"
      @input="onInput"
      @keydown.enter="onEnter"
      @keydown.escape="emit('escape', $event)"
      @keydown.down.exact="onArrowDown"
      @keydown.up.exact="onArrowUp"
      @focus="onFocus"
      @focusout="onFocusOut"
      @blur="emit('blur', $event)"
      @keyup="emit('keyup', $event)"
      @paste.stop="emit('paste', $event)"
    ></textarea>
    <span
      :id="VALIDATION_MESSAGE_ID"
      aria-live="polite"
      :data-testid="testId + 'validationMessage'"
      :class="[
        'absolute',
        'transition',
        'text-danger-100',
        'bg-transparent',
        'font-semibold',
        'truncate',
        'max-w-[calc(100%-1.5rem)]',
        'px-1',
        'flex',
        border === 'full' && ['-bottom-2 end-3.5', 'text-12-16'],
        (border === 'bottom' || border === 'none') && ['-end-1', 'text-body-tiny'],
      ]"
      @mousedown.prevent
    >
      <div
        :class="[
          isInputFocused
            ? {
                'bg-light-ui-100 dark:bg-dark-ui-100': darkMode === 'auto',
                'bg-light-ui-100': darkMode === false,
                'bg-dark-ui-100': darkMode === true,
              }
            : {
                'bg-grey-100 dark:bg-grey-850': darkMode === 'auto',
                'bg-grey-100': darkMode === false,
                'bg-grey-850': darkMode === true,
              },
          'w-full',
          'h-0.5',
          'absolute',
          'start-0',
          'end-0',
          'top-1/2',
          'z-0',
          'overflow-x-hidden',
        ]"
      >
        <div :class="['h-full', 'w-0.5', 'rounded-full', 'absolute', '-start-0.25', 'bg-danger-100']" />
        <div :class="['h-full', 'w-0.5', 'rounded-full', 'absolute', '-end-0.25', 'bg-danger-100']" />
      </div>
      <div class="z-10">
        {{ !isValid ? validationMessage : null }}
      </div>
    </span>
  </div>
</template>
